<template>
  <div>
    <h2 class="faledia-caption">
      Darstellungsweisen beim halbschriftlichen Rechnen
    </h2>
    <v-card outlined>
      <v-card-title>Was sollen die Schüler:innen lernen?</v-card-title>
      <v-card-text>
        Die Kinder können...
        <ul>
          <li>
            verschiedene Darstellungsweisen beim halbschriftlichen Rechnen
            nachvollziehen.
          </li>
          <li>
            angemessene Darstellungsweisen beim halbschriftlichen Rechnen
            auswählen und anwenden.
          </li>
          <li>
            zwischen verschiedenen Darstellungsweisen beim halbschriftlichen
            Rechnen wechseln.
          </li>
          <li>
            ihr Vorgehen beim halbschriftlichen Rechnen mithilfe von
            Darstellungen erläutern.
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <p>
      Da die Notationsweise beim halbschriftlichen Rechnen nicht verbindlich
      festgelegt ist, notieren Kinder nicht unbedingt alle Teilschritte, die sie
      gedanklich vollziehen. Das Notieren der einzelnen Schritte dient als
      Merkhilfe beim Rechnen. Dies kann jedoch zum Teil dazu führen, dass das
      Vorgehen der Kinder nicht immer eindeutig nachvollziehbar ist und erfragt
      werden muss. Deshalb kann es sinnvoll sein, mit den Kindern Konventionen
      zur Dokumentation zu erarbeiten und festzulegen. Dadurch wird eine
      gemeinsame Basis geschaffen, um über Aufgaben und Strategien zu
      kommunizieren und diese nachvollziehen zu können. Außerdem können durch
      eine ordentliche und sinnstiftende Dokumentation Fehler vermieden werden
      (Götze et al., 2019).
    </p>
    <p>
      Die Darstellung eines Rechenwegs ist dabei nicht nur in der Normal- oder
      Gleichungsschreibweise (also symbolisch) denkbar und sinnvoll. Auch
      Materialhandlungen oder das Verbildlichen des Vorgehens bieten sich an.
      Auch das Versprachlichen des eigenen Vorgehens ist wichtig, um
      Vorstellungen zu festigen und Vorgehen zu reflektieren.
    </p>
    <p>
      Wichtig ist: nicht immer bietet sich jede Verbildlichung oder jedes
      Material gleich gut für jede Strategie in der jeweiligen Rechenoperation
      an. Müssen zum Beispiel negative Zwischenergebnisse dargestellt werden,
      stößt man mit einer Materialdarstellung schnell an deren Grenzen. Auch
      sind bildliche Darstellungen nicht immer sofort eindeutig, sondern müssen
      mit den Kindern im Vorfeld besprochen und Konventionen festgelegt werden.
      Eine Auswahl an möglichen schriftlichen Notationen, teilweise verknüpft
      mit bildlichen Darstellungen des Rechenwegs, finden Sie im Folgenden.
    </p>
    <div class="flex">
      <div class="image">
        <AppExpandableImage
          contain
          :src="require('@/assets/hr/DW_Ab1.png')"
          parentId="swve"
          elementId="swve_darstellungswechsel.png"
        >
        </AppExpandableImage>
        <AppExpandableImage
          contain
          :src="require('@/assets/hr/DW_Ab2.png')"
          parentId="swve"
          elementId="swve_darstellungswechsel.png"
        >
        </AppExpandableImage>
      </div>
      <p>
        Eine Schreibweise, die bei allen Strategien und Rechenoperationen
        angewendet werden kann, ist die Gleichungsschreibweise. Dabei werden die
        einzelnen Teilschritte unter der Hauptaufgabe notiert und abschließend
        oben zusammengefügt (Padberg, Benz, 2021). In dieser Darstellungsweise
        (wie auch in andere Darstellungsweisen) können Kinder mithilfe von
        Forschermitteln, wie farbigen Markierungen oder Pfeilen, ihre Rechenwege
        für Mitlernenden nachvollziehbar machen. Dies kann sowohl bei einer
        Versprachlichung des eigenen Vorgehens unterstützen als auch durch eine
        sprachliche Erklärung unterstützt werden (Selter, Zannetin, 2019).
      </p>
    </div>

    <div class="flex">
      <div class="image">
        <AppExpandableImage
          contain
          :src="require('@/assets/hr/DW_Ab3.png')"
          parentId="swve"
          elementId="swve_darstellungswechsel.png"
        >
        </AppExpandableImage>
      </div>
      <p>
        In der nebenstehenden Abbildung sehen Sie die vereinfachte und
        verbildlichte Darstellung einer Handlung am Würfelmaterial zur
        stellenweisen Lösung der Aufgabe 447 – 172. Grundsätzlich bietet sich
        das Material oder deren Abbildung gut an, um ein stellenweises Vorgehen
        darzustellen. Auch das Bündeln und Entbündeln kann dabei anschaulich
        beachtet werden (Padberg, Benz, 2021). Bei dieser Aufgabe muss an der
        Zehnerstelle eine Entbündelung vorgenommen werden, da im Minuenden nicht
        ausreichend Zehner vorhanden sind, um die 70 Zehner des Subtrahenden
        abzuziehen. Wie Sie an diesem Beispiel vielleicht feststellen, sind
        Abbildungen, die eine dynamische Handlung repräsentieren, allerdings
        nicht aus sich heraus aussagekräftig und verständlich. Im folgenden
        Video, können Sie sich die Handlung ansehen, die hinter dem Bild steckt.
        Wichtig ist auch im schulischen Kontext, dass Sie mit den Kindern immer
        klären, wie eine Abbildung zu lesen ist, damit diese unterstützend
        wirken kann. Ohne eine vorherige Klärung können Kinder Material oder
        deren Abbildungen mitunter nicht verstehen – manchmal kann es sogar zu
        Verständnishürden führen, anstatt Verständnishilfe zu sein.
      </p>
    </div>
    <video
      class="lbst-border"
      preload="auto"
      controls
      width="60%"
      style="margin-left: 20%"
      @play="
        videoPlayerEvent($event, 'halbschriftliche-Subtraktion-Video.mp4.mp4')
      "
      @fullscreenchange="
        videoPlayerEvent($event, 'halbschriftliche-Subtraktion-Video.mp4.mp4')
      "
      @webkitfullscreenchange="
        videoPlayerEvent($event, 'halbschriftliche-Subtraktion-Video.mp4.mp4')
      "
      @mozfullscreenchange="
        videoPlayerEvent($event, 'halbschriftliche-Subtraktion-Video.mp4.mp4')
      "
      @msfullscreenchange="
        videoPlayerEvent($event, 'halbschriftliche-Subtraktion-Video.mp4.mp4')
      "
      @ended="
        videoPlayerEvent($event, 'halbschriftliche-Subtraktion-Video.mp4.mp4')
      "
      @pause="
        videoPlayerEvent($event, 'halbschriftliche-Subtraktion-Video.mp4.mp4')
      "
      @seeking="
        videoPlayerEvent($event, 'halbschriftliche-Subtraktion-Video.mp4.mp4')
      "
      @enterpictureinpicture="
        videoPlayerEvent($event, 'halbschriftliche-Subtraktion-Video.mp4')
      "
      @leavepictureinpicture="
        videoPlayerEvent($event, 'halbschriftliche-Subtraktion-Video.mp4')
      "
    >
      <!-- @seeking="videoPlayerEvent($event, 'halbschriftliche-Subtraktion-Video.mp4.mp4')" -->
      <source
        src="@/assets/hr/halbschriftliche-Subtraktion-Video.mp4"
        type="video/mp4"
      />
    </video>
    <div class="flex">
      <div class="image">
        <AppExpandableImage
          contain
          :src="require('@/assets/hr/DW_Ab4.png')"
          parentId="swve"
          elementId="swve_darstellungswechsel.png"
        >
        </AppExpandableImage>
      </div>
      <p>
        Bei der Multiplikation bietet sich bei einem stellenweisen
        halbschriftlichen Vorgehen das Hunderterfeld gut als Veranschaulichung
        an. Ein häufiger Fehler von Kindern ist es, dass lediglich die gleichen
        Stellenwerte miteinander multipliziert werden. Hier also nur Zehner mal
        Zehner und Einer mal Einer. Am Hunderterfeld kann nachvollziehbar und
        verständlich dargestellt werden, warum damit das Gesamtprodukt noch
        nicht ermittelt ist und welche Teilrechnungen noch fehlen. Allerdings
        ist es auch hier wichtig, die Darstellung mit den Kindern zu besprechen
        und die Zuordnung der Teilrechnungen in der Visualisierung begründet
        nachzuvollziehen.
      </p>
    </div>
    <video
      class="lbst-border"
      preload="auto"
      controls
      width="60%"
      style="margin-left: 20%"
      @play="
        videoPlayerEvent($event, 'halbschriftliches-Rechnen-Hunderterfeld.mp4')
      "
      @fullscreenchange="
        videoPlayerEvent($event, 'halbschriftliches-Rechnen-Hunderterfeld.mp4')
      "
      @webkitfullscreenchange="
        videoPlayerEvent($event, 'halbschriftliches-Rechnen-Hunderterfeld.mp4')
      "
      @mozfullscreenchange="
        videoPlayerEvent($event, 'halbschriftliches-Rechnen-Hunderterfeld.mp4')
      "
      @msfullscreenchange="
        videoPlayerEvent($event, 'halbschriftliches-Rechnen-Hunderterfeld.mp4')
      "
      @ended="
        videoPlayerEvent($event, 'halbschriftliches-Rechnen-Hunderterfeld.mp4')
      "
      @pause="
        videoPlayerEvent($event, 'halbschriftliches-Rechnen-Hunderterfeld.mp4')
      "
      @seeking="
        videoPlayerEvent($event, 'halbschriftliches-Rechnen-Hunderterfeld.mp4')
      "
      @enterpictureinpicture="
        videoPlayerEvent($event, 'halbschriftliches-Rechnen-Hunderterfeld.mp4')
      "
      @leavepictureinpicture="
        videoPlayerEvent($event, 'halbschriftliches-Rechnen-Hunderterfeld.mp4')
      "
    >
      <!-- @seeking="videoPlayerEvent($event, 'halbschriftliches-Rechnen-Hunderterfeld.mp4')" -->
      <source
        src="@/assets/hr/halbschriftliches-Rechnen-Hunderterfeld.mp4"
        type="video/mp4"
      />
    </video>
    <div class="flex">
      <div class="image">
        <AppExpandableImage
          contain
          :src="require('@/assets/hr/DW_Ab5.png')"
          parentId="swve"
          elementId="swve_darstellungswechsel.png"
        >
        </AppExpandableImage>
      </div>
      <p>
        Plättchendarstellungen können zum Beispiel auch dabei helfen, die
        Konstanzgesetze mit gegen- oder gleichsinnigem Verändern der Aufgabe,
        anschaulich nachvollziehen zu können. An diesem Beispiel zur Subtraktion
        kann erfahrbar gemacht werden, dass die Differenz gleich bleibt, wenn
        sowohl Minuend als auch Subtrahend gleichsinnig um denselben Wert
        verändert werden. Denn werden zwei Plättchen hinzugefügt, die
        entsprechend auch mehr abgezogen werden, gleicht sich dies in der
        Rechnung aus – ein solches Vorgehen kann das Berechnen einiger Aufgaben
        deutlich erleichtern.
      </p>
    </div>
    <div class="flex">
      <div class="image">
        <AppExpandableImage
          contain
          :src="require('@/assets/hr/DW_Ab6.png')"
          parentId="swve"
          elementId="swve_darstellungswechsel.png"
        >
        </AppExpandableImage>
      </div>
      <p>
        Eine Darstellung am Rechenstrich kann Kindern helfen, die Relation
        zwischen Zahlen nachzuvollziehen und bestimmte Zahlen in den Blick zu
        nehmen (Selter, Zannetin, 2019). Beispielweise kann dieser als
        Veranschaulichung für die Strategie „Schrittweise“ genutzt werden. Dabei
        lässt der Rechenstrich unterschiedliche Schritte zu und kann die
        Denkprozesse von Kindern unterstützen (Padberg, Benz, 2021). Ebenfalls
        ist der Rechenstrich bei der Strategie „Ergänzen“ ein gutes
        Darstellungsmittel, wodurch die einzelnen Schritte gut verdeutlich
        werden können (Padberg, Benz, 2021). Zur Darstellung eines stellenweisen
        Vorgehens, eignet sich der Rechenstrich jedoch nicht. Da sowohl erste
        als auch zweite Zahl zerlegt werden, kann kein Startpunkt auf dem
        Zahlenstrahl festgelegt werden.
      </p>
    </div>
    <p>
      Einige der üblichen Darstellungsweisen halbschriftlicher Strategien haben
      Sie nun kennengelernt. Wichtig ist es immer, dass Lernende in der Lage
      sind, verschiedene Darstellungen miteinander in Bezug zu setzten und diese
      dadurch zu vernetzen. Im Folgenden haben Sie nun selbst einmal die
      Gelegenheit sich mit dem Vernetzen verschiedener Darstellung zu einer
      Aufgabe auseinanderzusetzen. Dafür sollen Sie jeweils die Darstellungen
      zusammen gruppieren, die die gleiche Aufgabe abbilden.
    </p>
    <LernbausteinGruppen
      :id="LBSTGruppen.id"
      :checkAfterHowManyItems="LBSTGruppen.checkAfterHowManyItems"
      :checkAfterExactItemCount="LBSTGruppen.checkAfterExactItemCount"
      :showGroupNames="LBSTGruppen.showGroupNames"
      :workOrder="LBSTGruppen.workOrder"
      :possibleCategories="LBSTGruppen.possibleCategories"
      :elements="LBSTGruppen.elements"
      :responses="LBSTGruppen.responses"
    >
      <template v-slot:alternative-content>
        <v-simple-table class="imageTable">
          <template v-slot:default>
            <tbody>
              <tr>
                <td>Strategie</td>
                <td colspan="2">Darstellung</td>
              </tr>
              <tr>
                <td>
                  <strong>Stellenweise</strong>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/hr/Gruppen/DW/01.png')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/223_3.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/hr/Gruppen/DW/05.png')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/223_3.jpg"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Schrittweise</strong>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/hr/Gruppen/DW/06.png')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/223_3.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/hr/Gruppen/DW/03.png')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/223_3.jpg"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Hilfsaufgabe</strong>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/hr/Gruppen/DW/07.png')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/223_3.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/hr/Gruppen/DW/04.png')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/223_3.jpg"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Vereinfachen</strong>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/hr/Gruppen/DW/02.png')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/223_3.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/hr/Gruppen/DW/08.png')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/223_3.jpg"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </LernbausteinGruppen>
    <p>
      Ihnen ist es vielleicht nicht ganz so schwergefallen, die Darstellungen zu
      einer Aufgabe zu identifizieren. Für Kinder, die gerade die
      halbschriftlichen Strategien erst erlernen und zudem vielleicht auch noch
      nicht ganz so sicher mit den verschiedenen Darstellungsweisen umgehen,
      kann das Vernetzen der Darstellungen durchaus herausfordernd sein. Dabei
      bietet die Vernetzung verschiedener Darstellungsformen – unter anderem –
      jedoch viel Reflexionspotential. Auch Sie werden vermutlich in der
      Aktivität gemerkt haben, dass Sie über die einzelnen Schritte und
      Beziehungen der Aufgaben während der Bearbeitung nachgedacht haben.
      Besonders wirkungsvoll ist es daher immer auch eine sprachliche Begleitung
      einfließen zu lassen und Kinder mit der Frage „Warum passt diese
      Darstellung zu der Aufgabe?“ zu konfrontieren.
    </p>
    <AppLiteraturHR />
    <AppBottomNavHR
      back="/zahlenrechnen/hintergrundwissen/flexibler-einsatz-von-rechenstrategien"
      next="/zahlenrechnen/uebergang"
    />
  </div>
</template>

<script>
import AppHelper from "@/common/AppHelper";
import AppBottomNavHR from "@/common/AppBottomNavHR";
import AppLiteraturHR from "@/common/AppLiteraturHR";
import AppExpandableImage from "@/common/AppExpandableImage";

import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LBST_Gruppen_DW from "@/components/faledia/seiten/zahlenrechnen/LBST/LBST_Gruppen_DW";

export default {
  components: {
    AppBottomNavHR,
    AppLiteraturHR,
    AppExpandableImage,
    LernbausteinGruppen,
  },
  data: () => ({
    LBSTGruppen: LBST_Gruppen_DW,
  }),
  methods: {
    videoPlayerEvent(event, name) {
      AppHelper.videoPlayerEvent(this, event, name);
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
}
.image {
  max-width: 30%;
  margin-inline-end: 1%;
  margin-block-end: 1%;
}
.imageTableImages {
  margin-top: 10px;
  margin-bottom: 10px;
}
.imageTable {
  margin-bottom: 20px;
}
.tableImageContainer {
  width: 179px;
}
</style>
