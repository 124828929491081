function createObject() {
  let example = {
    id: "LBST_Gruppen_DW",
    showGroupNames: true,
    checkAfterHowManyItems: 2,
    checkAfterExactItemCount: true,
    workOrder:
      "Sie sehen hier Aufgaben, die mithilfe verschiedener halbschriftlicher Strategien gerechnet wurden und auf unterschiedliche Weise dargestellt sind. Ziehen sie jeweils 2 Aufgaben in eine Gruppe, bei denen Ihrer Meinung nach dieselbe Strategie verwendet wurde.",
    possibleCategories: [
      {
        id: 1,
        name: "Stellenweise",
      },
      {
        id: 2,
        name: "Schrittweise",
      },
      {
        id: 3,
        name: "Hilfsaufgabe",
      },
      {
        id: 4,
        name: "Vereinfachen",
      },
    ],
    elements: [
      {
        id: 1,
        image: require("@/assets/hr/Gruppen/DW/01.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 2,
        image: require("@/assets/hr/Gruppen/DW/02.png"),
        category: 4,
        flex: 3,
      },
      {
        id: 3,
        image: require("@/assets/hr/Gruppen/DW/03.png"),
        category: 2,
        flex: 3,
      },
      {
        id: 4,
        image: require("@/assets/hr/Gruppen/DW/04.png"),
        category: 3,
        flex: 3,
      },
      {
        id: 5,
        image: require("@/assets/hr/Gruppen/DW/05.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 6,
        image: require("@/assets/hr/Gruppen/DW/06.png"),
        category: 2,
        flex: 3,
      },
      {
        id: 7,
        image: require("@/assets/hr/Gruppen/DW/07.png"),
        category: 3,
        flex: 3,
      },
      {
        id: 8,
        image: require("@/assets/hr/Gruppen/DW/08.png"),
        category: 4,
        flex: 3,
      },
    ],
  };

  const einsRichtig =
    "Eine der Aufgaben zeigt die passende halbschriftliche Strategie, eine zeigt jedoch eine andere halbschriftliche Strategie.";

  example.responses = [
    {
      id: "0002",
      category: example.possibleCategories[3],
      text:
        "Richtig, hier wird die Strategie Vereinfachen gezeigt. Bei dieser Strategie wird die Aufgabe auf Grundlage der Konstanzgesetze vereinfacht.",
    },
    {
      id: "0020",
      category: example.possibleCategories[2],
      text:
        "Prima. In beiden Aufgaben wird die Strategie Hilfsaufgabe dargestellt. Bei dieser Strategie wird eine ähnliche, einfachere Aufgabe aufgestellt und anschließende korrigiert, um das richtige Ergebnis zu erhalten.",
    },
    {
      id: "0200",
      category: example.possibleCategories[1],
      text:
        "Sehr gut. Alle drei Aufgaben zeigen die Strategie Schrittweise. Bei dieser Strategie wird eine Zahl zerlegt und es wird mit Teilergebnissen weiter gerechnet.",
    },
    {
      id: "2000",
      category: example.possibleCategories[0],
      text:
        "Super. Beide Aufgaben repräsentieren die Strategie Stellenweise. Bei dieser Strategie werden beide Zahlen in ihre Stellwerte zerlegt, erst einzeln ausgerechnet und anschließend miteinander verknüpft.",
    },
    {
      id: "0011",
      text: einsRichtig,
    },
    {
      id: "0101",
      text: einsRichtig,
    },
    {
      id: "0110",
      text: einsRichtig,
    },
    {
      id: "1001",
      text: einsRichtig,
    },
    {
      id: "1010",
      text: einsRichtig,
    },
    {
      id: "1100",
      text: einsRichtig,
    },
  ];

  return example;
}

export default createObject();
